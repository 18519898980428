<template>
  <div class="page1">
    <div class="breadcrumb"></div>
    <!--主体内容-->
    <div class="content">
      <!--搜索条件区域-->
      <div class="searchWrapper" @keydown.enter.prevent="searchData">
        <el-form :inline="true" label-position="right" :model="formInline" class="demo-form-inline">
          <div class="search_box_title">{{ $t('searchModule.Query_Table') }}</div>
          <div class="col_box">
            <div class="col_left">
              <el-form-item :label="$t('searchModule.Equipment_name')">
                <el-input
                  v-model="formInline.monitorEquipmentName"
                  placeholder="请输入内容"
                ></el-input>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Road_name')">
                <auto-complete
                  ref="select"
                  @dataChange="(v) => (temParkData = v)"
                  slaveRelations="0,2"
                  @valueChange="completeValue"
                  @queryPark="queryPark"
                  :parkData="parkData"
                ></auto-complete>
              </el-form-item>
              <el-form-item :label="$t('searchModule.address')">
                <el-input v-model="formInline.address" placeholder="请输入内容"></el-input>
              </el-form-item>
              <!-- <div> -->
              <el-form-item :label="$t('searchModule.Merchant_Name')">
                <el-select v-model.trim="formInline.operationId" filterable size="15">
                  <el-option label="全部" value=""></el-option>
                  <el-option
                    :label="value.operationName"
                    :value="value.operationId"
                    :key="value.operationId"
                    v-for="value in tenantList"
                  ></el-option>
                </el-select>
              </el-form-item>
            </div>
            <div class="col_right">
              <el-button
                type="primary"
                icon="el-icon-search"
                @click="
                  page = 1;
                  searchData();
                "
                :loading="loading"
                >{{ $t('button.search') }}</el-button
              >
            </div>
          </div>
          <div class="col_box_boder"></div>
          <div class="col_box h44">
            <div class="col_left">
              <el-button
                type="primary"
                icon="el-icon-plus"
                v-if="$route.meta.authority.button.add"
                @click="$router.push('/addVideoMonitorEquip')"
                >{{ $t('button.addto') }}</el-button
              >
            </div>
            <div class="col_right mbd4">
              <el-button type="info" icon="el-icon-download" @click="openMuli">{{ $t('button.import') }}</el-button>
            </div>
          </div>
          <!-- </div> -->
        </el-form>
      </div>
      <upload-file
        ref="upload"
        :uploadData="uploadData"
        @success="success"
        :uploadUrl="uploadUrl"
        :modelUrl="modelUrl"
      ></upload-file>
      <!--列表区域-->
      <div class="tableWrapper bgFFF paddingB10">
        <el-table
          header-cell-class-name="header-call-style"
          v-loading="loading"
          :data="tableData"
          style="width: 100%"
        >
          <el-table-column type="index" :label="$t('list.index')" width="70" align="center"></el-table-column>
          <!-- <el-table-column type="selection" width="55"></el-table-column> -->
          <el-table-column
            :prop="item.prop"
            :label="item.label"
            :width="item.width"
            v-for="item in tableCols"
            :key="item.prop"
            :formatter="item.formatter"
            align="center"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            label="操作"
            align="center"
            width="90"
            v-if="
              $route.meta.authority.button.edit ||
              $route.meta.authority.button.delete ||
              $route.meta.authority.button.monitor
            "
          >
            <template slot-scope="scope">
              <el-dropdown @command="handleCommand($event, scope.row)">
                <el-button type="text" style="padding: 0" size="small"
                  >操作<i class="el-icon-arrow-down" style="margin-left: 4px"
                /></el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="a" v-if="$route.meta.authority.button.monitor"
                    >查看监控</el-dropdown-item
                  >
                  <el-dropdown-item command="b" v-if="$route.meta.authority.button.edit"
                    >编辑</el-dropdown-item
                  >
                  <el-dropdown-item command="c" v-if="$route.meta.authority.button.delete"
                    >删除</el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
        </el-table>
        <!--分页器-->
        <div class="pagerWrapper">
          <div class="block">
            <el-pagination
              @current-change="handleCurrentChange"
              :current-page="page"
              :page-size="pageSize"
              layout="total, prev, pager, next, jumper"
              :total="total"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
    <video-monitor
      ref="videoMonitor"
      :passageway-list="passagewayList"
      :isShowChange="isShowChange"
      @change="videoChange"
      v-if="isShowVideoDialog"
      :title="title"
      :isPlayBack="isPlayBack"
      @close="isShowVideoDialog = false"
    ></video-monitor>
  </div>
</template>

<script>
/* eslint-disable */
import autoComplete from "@/components/autocomplete/myautoComponent.vue";
import VideoMonitor from "@/components/video-monitor/";
import uploadFile from "@/components/uploadFile/";
export default {
  name: "videoMonitorEquipment",
  data() {
    return {
      passagewayList: [],
      uploadUrl: "/acb/2.0/monitorEquipment/import",
      modelUrl: "/acb/2.0/monitorEquipment/downloadExcelTemplate",
      uploadData: "",
      isShowVideoDialog: false,
      isShowChange: false,
      title: "",
      temParkData: {},
      parkData: [],
      tenantList: [],
      index: 0,
      total: 0,
      pageSize: 15,
      page: 1,
      parkPage: 1,
      loading: false,
      detailFlag: false,
      tableCols: [
        {
          prop: "monitorEquipmentName",
          label: this.$t("list.Equipment_name"),
          width: "",
        },
        {
          prop: "operationName",
          label: this.$t("list.Merchant_affiliation"),
          width: "",
        },
        {
          prop: "parkName",
          label: "所在道路",
          width: "",
        },
        {
          prop: "address",
          label: this.$t("list.address"),
          width: "",
        },
        {
          prop: "netTypeVal",
          label: "网络模式",
          width: "",
        },
        {
          prop: "ipAddress",
          label: "IP地址/设备编码",
          width: "150",
        },
        {
          prop: "ipPort",
          label: this.$t("list.Port_number"),
          width: "",
        },
        {
          prop: "monitorPort",
          label: this.$t("list.video_Port_number"),
          width: "90",
        },
        {
          prop: "account",
          label: "用户名",
          width: "",
        },
        {
          prop: "password",
          label: "密码",
          width: "",
        },
        {
          prop: "createdTime",
          label: this.$t("list.Creation_date"),
          width: "170",
        },
      ],
      tableData: [],
      formInline: {
        monitorEquipmentName: "",
        parkId: "",
        address: "",
        operationId: "",
      },
      passagewayPageSize: 5,
      passagewayPage: 1,
      passagewayTotal: 0,
      selectData: {},
      isPlayBack: true, // 是否支持回放
    };
  },
  methods: {
    async videoChange() {
      if (this.passagewayPage > this.passagewayFlag) {
        this.passagewayPage = 1;
      }
      await this.getPassagewayList(this.selectData.monitorEquipmentId);
      this.$nextTick(() => {
        this.$refs.videoMonitor.clearData();
      });
    },
    /* 运营商名称的下拉框 */
    getTenantList() {
      this.$axios.get("/acb/2.0/operation/nameList").then((res) => {
        this.loading = false;
        if (res.state == 0) {
          this.tenantList = res.value;
        } else {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
        }
      });
    },
    openMuli() {
      this.$refs.upload.clearFiles();
      this.$refs.upload.open();
    },
    success() {
      this.searchData();
    },
    addBerth() {
      this.$refs.addBerth.open();
    },
    completeValue(item) {
      this.formInline.parkId = item.parkId;
    },
    queryPark() {
      this.parkPage++;
      this.getPark(this.area);
    },
    getPark(areaId) {
      this.area = areaId;
      let url = "/acb/2.0/systems/loginUser/getParkName";
      this.$axios
        .get(url, {
          data: {
            page: this.parkPage,
            pageSize: 15,
            areaIds: "",
          },
        })
        .then((res) => {
          if (res.state == 0) {
            this.parkData = res.value.list;
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
    getPassagewayList(monitorEquipmentId) {
      return new Promise((resolve, reject) => {
        this.$axios
          .get("/acb/2.0/monitorEquipmentChannel/list", {
            data: {
              page: this.passagewayPage,
              pageSize: this.passagewayPageSize,
              monitorEquipmentId,
            },
          })
          .then((res) => {
            if (res.state == 0) {
              this.passagewayList = res.value.list;
              this.passagewayTotal = res.value.total;
              this.passagewayFlagA = this.passagewayTotal % this.passagewayPageSize;
              this.passagewayFlagB = parseInt(this.passagewayTotal / this.passagewayPageSize);
              if (this.passagewayFlagA > 0) {
                this.passagewayFlag = this.passagewayFlagB + 1;
              } else {
                this.passagewayFlag = this.passagewayFlagB;
              }
              console.log(this.passagewayFlag);
              this.passagewayPage++;
              // this.passagewayFlag = this.passagewayTotal/this.passagewayPageSize
            } else {
              this.passagewayPage = 1;
              this.passagewayList = [];
              this.passagewayTotal = 0;
            }
            if (this.passagewayTotal > 5) {
              this.isShowChange = true;
            } else {
              this.isShowChange = false;
            }
            resolve();
          });
      });
    },
    async handleCommand(cmd, data) {
      if (cmd == "a") {
        // this.title = `${this.parkDetailValue.parkName}监控设备`
        // this.isShowVideoDialog = true;
        // this.$nextTick(() => {
        //   this.$refs.videoMonitor.open();
        // });
        this.passagewayPageSize = 5;
        this.passagewayPage = 1;
        this.passagewayTotal = 0;
        await this.getPassagewayList(data.monitorEquipmentId);
        if (this.passagewayList.length == 0) {
          this.$alert("此NVR下没有监控设备！", this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
          return;
        }
        this.selectData = data;
        this.title = `${data.monitorEquipmentName}`;
        // this.passagewayList = [data];
        this.isShowVideoDialog = true;
        this.$nextTick(() => {
          this.$refs.videoMonitor.open();
        });
        // window.open('/video.html?' + data.ipAddress + '/' + data.ipPort + '/' + data.account + '/' + data.password + '/' + data.monitorPort, '查看监控设备')
      }
      if (cmd == "b") {
        this.$router.push({
          path: "/editVideoMonitorEquip",
          query: data,
        });
      }
      if (cmd == "c") {
        this.deleteItem(data);
      }
    },
    // 分页处理
    handleCurrentChange(val) {
      this.page = val;
      this.searchData();
    },
    deleteItem({ monitorEquipmentId }) {
      this.$confirm("此操作将删除该条数据, 是否继续?", this.$t('pop_up.Tips'), {
        confirmButtonText: this.$t('pop_up.Determine'),
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$axios.post("/acb/2.0/monitorEquipment/delete/" + monitorEquipmentId).then((res) => {
            this.loading = false;
            if (res.state == 0) {
              this.searchData();
            } else {
              this.$alert(res.desc, this.$t('pop_up.Tips'), {
                confirmButtonText: this.$t('pop_up.Determine'),
              });
            }
            this.loading = false;
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
    // 搜索
    searchData() {
      console.log(1);
      this.tableData = [];
      if (!this.formInline.parkId && Object.keys(this.temParkData).length) {
        this.$refs.select.setShowVal(this.temParkData.parkName);
        this.formInline.parkId = this.temParkData.parkId;
        this.temParkData = {};
      }
      if (!this.formInline.parkId) {
        this.$refs.select.setShowVal("");
      }
      this.loading = true;
      this.$axios
        .get("/acb/2.0/monitorEquipment/listMonitorEquipment", {
          data: {
            page: this.page,
            pageSize: this.pageSize,
            ...this.formInline,
          },
        })
        .then((res) => {
          this.loading = false;
          if (res.state == 0) {
            this.tableData = res.value.list;
            this.total = res.value.total * 1;
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
  },
  activated() {
    this.getPark();
    this.getTenantList();
    this.searchData();
  },
  beforeDestroy() {
    sessionStorage.searchData = JSON.stringify(this.formInline);
    sessionStorage.searchFlag = 1;
  },
  components: {
    autoComplete,
    VideoMonitor,
    uploadFile,
  },
  created() {
    this.getPark();
    this.getTenantList();
  },
  mounted() {
    this.searchData();
  },
  computed: {},
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus">
.breadcrumb
  // height: 35px;
.content
  // background: #FFFFFF;
  overflow: hidden;
  .searchWrapper
    overflow: hidden;
    // padding:22px 22px 0;
    // background: #EFF2F7;
    // margin-bottom: 20px;
  .pagerWrapper
    text-align: right;
    margin-top: 18px;
    font-size: 12px;
    .export
      font-size: 12px;
      .iconfont
        margin-right: 0;
.detail
  width: 61.4%;
  height: 82.9%;
  position: absolute;
  top: 8.6%;
  left: 20.1%;
  background: #FFFFFF;
  box-shadow: 0 0 4px 1px rgba(128,145,165,0.30);
  border-radius: 3px;
  z-index: 1000;
  box-sizing: border-box;
  padding: 20px 0;
  .title
    position: absolute;
    background: #3F4A56;
    border-radius: 3px 3px 0 0;
    color: #fff;
    width: 100%;
    top: 0;
    left: 0;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    padding: 0 12px;
    box-sizing: inherit;
    z-index: 100;
    .el-icon-close
      float: right;
      margin-top: 14px;
  .info
    margin-top: 20px;
    padding: 2.1%;
    height: 100%;
    overflow: auto;
    box-sizing: inherit;
.mask
  background: rgba(49,53,65,0.60);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
.el-dropdown-link
 cursor pointer
</style>
